import React from 'react'

import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/SEO'
import PageHero from '../components/shared/page-hero'
import PageBody, {
  PageContainer,
  ContentImage,
} from '../components/shared/page-body'
import EveningSuitsBanner from '../components/banners/evening-suits'

const BusinessSuitsPage = ({ data }) => (
  <Layout>
    <Seo
      title="Tailored Business Suits and Corporate Wear"
      pathname="/business-suits/"
    />
    <PageHero
      image={data.hero.childImageSharp}
      title="Tailored Business Suits and Corporate Wear"
    />
    <PageBody>
      <PageContainer maxWidth={840} padded>
        <h1 className="heading--h1">Tailored Business Suits</h1>
        <p>
          Price is usually top of peoples' concerns for not owning a tailored
          suit. Too often tailoring is thought of as only for special occasions
          — most often a wedding.
        </p>
        <p>
          Our best advice is that the occasion doesn’t have to be special. It
          needs to be normal. Investing in your business wardrobe will pay
          dividends every time you put on a suit and feel confident.
        </p>
        <ContentImage
          {...data.blueSuitStaircase.childImageSharp}
          style={{ maxHeight: '720px' }}
          imgStyle={{ objectPosition: '50% 30%' }}
          alt="Light Blue Tailored Business Suit with Pink Tie"
        />
        <ContentImage
          {...data.portrait.childImageSharp}
          style={{ maxHeight: '600px' }}
          imgStyle={{ objectPosition: '50% 60%' }}
          alt="Grey and Pink Check Double-Breasted Suit Portrait"
        />
        <ContentImage
          {...data.pocketDetail.childImageSharp}
          alt="Grey and Pink Check Double-Breasted Suit Pocket Detail"
        />
        <ContentImage
          {...data.darkSuitPurpleTie.childImageSharp}
          alt="Dark Tailored Business Suit with Purple Tie"
        />
        <h2 className="heading--h1">
          Tailor your business wardrobe and make the right impression{' '}
          <strong>when it matters.</strong>
        </h2>
        <p>
          <em>How many suits do you have?</em> Usually the answer is one or two.{' '}
          <em>How often are they worn?</em> For many people the answer is at
          least 5 days a week.
        </p>
        <p>
          <em>How long do your suits last and how often are they replaced?</em>{' '}
          Depending on material choice and your lifestyle, even the best made
          suits can require replacement after 6-9 months.
        </p>
        <p>
          <em>Why does a garment only last this long?</em>
        </p>
        <ul>
          <li>Too much wear and tear. Fabrics need to recover.</li>
          <li>Too much dry cleaning</li>
          <li>Not looked after properly</li>
          <li>Frequent travelling</li>
          <li>Not the right fabric for the function of the suit.</li>
          <li>
            Not a good investment. Replaced too often and therefore costly.
          </li>
        </ul>
        <p>
          <em>How long should a suit last?</em> How long is a piece of string?
          Not long if any of the reasons above apply to your lifestyle.
        </p>
        <p>
          Depending on your type of employment, Fox Tailoring will advise you on
          the right fabric, right style and how to get maximum longevity from
          your garment.
        </p>
        <ContentImage
          {...data.bluePinstripePortrait.childImageSharp}
          alt="Tailored Blue Pinstripe Suit Portrait"
          imgStyle={{
            objectPosition: '50% 10%',
          }}
          style={{ maxHeight: '840px' }}
        />
        <h3 className="heading--h3">
          Loyalty Discounts &amp; Corporate Packages
        </h3>
        <p>
          Please enquire for information about Fox Tailoring's corporate
          packages.
        </p>
      </PageContainer>
    </PageBody>
    <EveningSuitsBanner />
  </Layout>
)

export default BusinessSuitsPage

export const query = graphql`
  {
    hero: file(
      relativePath: { eq: "img/blue-pinstripe-business-suit-cuff-detail.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blueSuitStaircase: file(
      relativePath: { eq: "img/blue-suit-staircase.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 760, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    portrait: file(
      relativePath: { eq: "img/grey-pink-check-double-breasted-portrait.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 760, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pocketDetail: file(
      relativePath: { eq: "img/pink-check-suit-pocket-detail.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 760, maxHeight: 640, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bluePinstripePortrait: file(
      relativePath: { eq: "img/navy-pinstripe-suit-portrait.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 760, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    darkSuitPurpleTie: file(
      relativePath: { eq: "img/dark-business-suit-purple-tie.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 760, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
