import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Banner, CallToAction } from '../banner-cta'

const EveningSuitsBanner = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "img/speckled-evening-suit.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Banner
      image={{
        ...hero.childImageSharp,
        alt: 'Tailored evening suit with black bowtie',
        style: {
          minHeight: '360px',
        },
        imgStyle: {
          filter: 'brightness(60%)',
          objectFit: 'cover',
          objectPosition: 'bottom',
          fontFamily: 'object-fit: cover; object-position: bottom',
        },
      }}
    >
      <CallToAction
        heading="Rise to Any Occasion"
        // to="/evening-suits"
        buttonText="Evening Suits"
      />
    </Banner>
  )
}

export default EveningSuitsBanner
